import React from 'react'
import { Link } from 'react-router-dom'
import { Typography, Row, Col, Button } from 'antd'
import { routeConfig } from 'src/_constants/routes'

const createProductModelRoute = routeConfig.root.dashboard + routeConfig.dashboard.productModel + routeConfig.productModel.create

const ProductModelListHeader = () => {

  return (
    <Row>
      <Col span={12}>
        <Typography.Title level={2}>商品模組列表</Typography.Title>
      </Col>
      <Col offset={8} span={4}>
        <Link to={createProductModelRoute}>
          <Button type="primary" block>
            + 新增模組
          </Button>
        </Link>
      </Col>
    </Row>
  )
}

export default ProductModelListHeader
