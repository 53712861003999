import React from 'react'
import { Space, Button } from 'antd'
import { Link } from 'react-router-dom'
import { routeConfig } from 'src/_constants/routes'
import { useMutation } from '@apollo/client'
import { DELETE_POPSELECT_PRODUCT, LIST_POPSELECT_PRODUCT } from 'src/productModel/ProductGQL'
import {
  DeletePopSelectProduct,
  DeletePopSelectProductVariables,
} from '../../../apollo-types/DeletePopSelectProduct'

// TODO: waiting for api to implement actions
const Actions = ({ id }: Pick<any, 'id'>) => {
  const [deleteProductModel] = useMutation<DeletePopSelectProduct, DeletePopSelectProductVariables>(
    DELETE_POPSELECT_PRODUCT,
  )

  const handleRemove = async () => {
    const result = window.confirm('確定要刪除嗎')
    if (!result) {
      return
    }
    deleteProductModel({
      variables: {
        deletePopSelectProductId: id,
      },
      refetchQueries: [LIST_POPSELECT_PRODUCT],
    })
  }

  const editRoute =
    routeConfig.root.dashboard + routeConfig.dashboard.productModel + routeConfig.productModel.edit

  return (
    <Space size="middle">
      <Link to={editRoute.replace(':id', id)}>
        <Button type="primary">編輯</Button>
      </Link>
      <Button danger onClick={handleRemove}>
        刪除
      </Button>
    </Space>
  )
}

export default Actions
