import { gql } from '@apollo/client'

export const GET_CATEGORIES = gql`
  query Categories($categoryType: CategoryType, $categoryEnableType: CategoryEnableType) {
    categories(categoryType: $categoryType, categoryEnableType: $categoryEnableType) {
      id
      name
      enabled
      creator
      editor
      interest
      postID
    }
  }
`

export const SET_CATEGORY = gql`
  mutation Category($input: CategoryInput!) {
    setCategory(input: $input) {
      id
      name
      enabled
      creator
      editor
      interest
      postID
    }
  }
`
