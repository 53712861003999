import React from 'react'
import { Link } from 'react-router-dom'
import { Typography, Row, Col, Button } from 'antd'
import { routeConfig } from '../../_constants/routes'

const createCategoryRoute =
  routeConfig.root.dashboard + routeConfig.dashboard.category + routeConfig.category.create

const CategoryListHeader = ({ curTab }: any) => {
  return (
    <Row>
      <Col span={12}>
        <Typography.Title level={2}>分類列表</Typography.Title>
      </Col>
      <Col offset={8} span={4}>
        <Link to={createCategoryRoute}>
          {curTab === 'event' && (
            <Button type="primary" block>
              + 新增分類
            </Button>
          )}
        </Link>
      </Col>
    </Row>
  )
}

export default CategoryListHeader
