import React from 'react'
import { useNavigate, Outlet } from 'react-router'
import { navigatePath } from 'src/_constants/routes'

import DailyModuleList from './components/DailyModuleList'
import DailyModuleEditor from './components/DailyModuleEditor'

import { useDailyModuleListItems } from './daily.service'
import { DailyModuleEditorMode } from './daily.constants'

export { DailyModuleEditorMode }

export function Daily() {
  return <Outlet />
}

export function DailyList() {
  const navigate = useNavigate()
  const [items, status] = useDailyModuleListItems()
  const goToCreateDailyPage = React.useCallback(() => navigate(navigatePath.createDaily), [
    navigate,
  ])
  return (
    <DailyModuleList
      onCreateDaily={goToCreateDailyPage}
      items={items}
      isLoading={status === 'loading'}
    />
  )
}

const modeLabel = {
  [DailyModuleEditorMode.CREATE]: '新增',
  [DailyModuleEditorMode.EDIT]: '編輯',
}

export function DailyEditor({ mode }: { mode: DailyModuleEditorMode }) {
  return <DailyModuleEditor mode={modeLabel[mode]} />
}
