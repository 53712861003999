import React from 'react'
import { useNavigate } from 'react-router'
import { navigatePath } from 'src/_constants/routes'
import { useUserInfoContext } from 'src/_contexts/user-info.context'

import { validateToken } from './auth.service'
import LoginSpinner from './components/LoadingSpinner'

import { createLogger } from 'src/_utils/logger'

const logger = createLogger({ namespace: 'auth.module', logLevel: 'warn', enabled: true })

interface AuthProps {
  children: JSX.Element
  redirectTo: string
}

function Auth({ children, redirectTo }: AuthProps) {
  const { userInfo, validated, setUserInfo, setValidated } = useUserInfoContext()
  const navigate = useNavigate()

  React.useEffect(() => {
    let mounted = true
    if (validated) return
    if (!userInfo) {
      navigate(navigatePath.login, { replace: true })
      return
    }

    async function validateUserInfo() {
      if (!userInfo) return
      try {
        const { mid, name } = await validateToken({ token: userInfo.token })
        if (!mounted) return
        setValidated(true)
        setUserInfo({ ...userInfo, mid, name })
      } catch (error) {
        logger.log(error)
        if (!mounted) return
        window.alert(error.message)
        setUserInfo(null)
      }
    }

    validateUserInfo()

    return () => {
      mounted = false
    }
  }, [validated, userInfo, setUserInfo, setValidated, navigate, redirectTo])

  const withSpinner = (
    <LoginSpinner isLoading tip="驗證你的身份中...">
      {children}
    </LoginSpinner>
  )

  return validated ? children : withSpinner
}

export default Auth
