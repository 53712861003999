import { Form, Input, Checkbox, Button } from 'antd'
import React from 'react'
import ProductModelHeader from './ProductModelEditorHeader'
import { useMutation } from '@apollo/client'
import {
  CREATE_POPSELECT_PRODUCT,
  DELETE_POPSELECT_PRODUCT,
  SET_POPSELECT_PRODUCT,
} from '../ProductGQL'
import {
  CreatePopSelectProduct,
  CreatePopSelectProductVariables,
} from '../../apollo-types/createPopSelectProduct'
import { GetPopSelectProductById_getPopSelectProductById } from '../../apollo-types/GetPopSelectProductById'
import {
  SetPopSelectProduct,
  SetPopSelectProductVariables,
} from '../../apollo-types/SetPopSelectProduct'
import {
  DeletePopSelectProduct,
  DeletePopSelectProductVariables,
} from '../../apollo-types/DeletePopSelectProduct'
import { routeConfig } from '../../_constants/routes'
import { useParams, useNavigate } from 'react-router'
import { ProductModelEditorFormMode } from '.'
import { SpinWrapper } from '../../common'
import CATEGORIES_CONFIG from '../CategoriesConfig'

const productModelListRoute = routeConfig.root.dashboard + routeConfig.dashboard.productModel

interface ProductModelEditorFormProps {
  mode: ProductModelEditorFormMode
  data?: GetPopSelectProductById_getPopSelectProductById | null
}

const ProductModelEditorForm = (props: ProductModelEditorFormProps) => {
  const { mode, data } = props
  const { id } = useParams()
  const navigate = useNavigate()
  const mutationOptions = {
    onCompleted: () => {
      navigate(productModelListRoute)
    },
  }
  const [createPopSelectProduct, { loading: createLoading, error: createError }] = useMutation<
    CreatePopSelectProduct,
    CreatePopSelectProductVariables
  >(CREATE_POPSELECT_PRODUCT, mutationOptions)
  const [setPopSelectProduct, { loading: setLoading, error: setError }] = useMutation<
    SetPopSelectProduct,
    SetPopSelectProductVariables
  >(SET_POPSELECT_PRODUCT, mutationOptions)
  const [deleteProductModel, { loading: deleteLoading, error: deleteError }] = useMutation<
    DeletePopSelectProduct,
    DeletePopSelectProductVariables
  >(DELETE_POPSELECT_PRODUCT, mutationOptions)

  const onFinish = (values: any) => {
    if (mode === ProductModelEditorFormMode.CREATE) {
      createPopSelectProduct({
        variables: {
          input: {
            ...values,
            urls: values.urls ? values.urls.split(',').map((s: string) => s.trim()) : [],
          },
        },
      })
      return
    }

    setPopSelectProduct({
      variables: {
        setPopSelectProductId: id,
        input: {
          ...values,
          urls: values.urls ? values.urls.split(',').map((s: string) => s.trim()) : [],
        },
      },
    })
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  const handleDelete = () => {
    if (!id) {
      return
    }
    const result = window.confirm('確定要刪除嗎')
    if (!result) {
      return
    }
    deleteProductModel({
      variables: {
        deletePopSelectProductId: id,
      },
    })
  }

  if (createError || setError || deleteError) {
    navigate('/error')
    return null
  }

  const isLoading = createLoading || deleteLoading || setLoading

  return (
    <>
      <ProductModelHeader mode={mode} />
      <SpinWrapper tip="處理中" isLoading={isLoading}>
        {isLoading ? (
          <></>
        ) : (
          <Form
            name="productModel"
            labelCol={{ span: 2 }}
            wrapperCol={{ span: 15 }}
            layout="horizontal"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{
              title: data?.title,
              description: data?.description ?? '',
              categoryUrl: data?.categoryUrl ?? '',
              clas: (data?.clas as string[]) ?? [],
              urls: data?.urls?.join(',') ?? '',
            }}
          >
            <Form.Item wrapperCol={{ span: 17 }} className="text-right">
              <Button type="primary" htmlType="submit">
                {mode === ProductModelEditorFormMode.CREATE ? '新增' : '儲存'}{' '}
              </Button>
              {mode === ProductModelEditorFormMode.EDIT && (
                <Button
                  type="default"
                  htmlType="button"
                  danger
                  className="ml-2"
                  onClick={handleDelete}
                >
                  刪除
                </Button>
              )}
            </Form.Item>
            <Form.Item
              label="模組標題"
              name="title"
              required
              rules={[
                { required: true, message: '請輸入商品模組標題' },
                { max: 8, message: '模組標題最多8個字' },
              ]}
            >
              <Input placeholder="請輸入商品模組標題" />
            </Form.Item>
            <Form.Item
              label="說明文字"
              name="description"
              rules={[{ max: 30, message: '說明文字最多30個字' }]}
            >
              <Input placeholder="請輸入商品模組說明文字" />
            </Form.Item>
            <Form.Item label="分類網址" name="categoryUrl">
              <Input placeholder="分類頁網址" />
            </Form.Item>
            <Form.Item label="分類" name="clas">
              <Checkbox.Group style={{ width: '100%' }}>
                {Object.keys(CATEGORIES_CONFIG).map((key) => (
                  <Checkbox key={key} value={key}>
                    {CATEGORIES_CONFIG[key].name}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            </Form.Item>
            <Form.Item label="加入商品" name="urls">
              <Input.TextArea placeholder="貼上商品頁網址，並用逗號隔開" rows={10} />
            </Form.Item>
          </Form>
        )}
      </SpinWrapper>
    </>
  )
}

export default ProductModelEditorForm
