import React from 'react'
import { Breadcrumb, Row, Col, Typography } from 'antd'
import { Link } from 'react-router-dom'
import { routeConfig } from '../../_constants/routes'
import { ProductModelEditorFormMode } from './index'

const productModelListRoute = routeConfig.root.dashboard + routeConfig.dashboard.productModel

const ProductModelEditorBreadcrumb = ({ mode }: { mode: ProductModelEditorFormMode }) => {
  const title = `${mode === ProductModelEditorFormMode.CREATE ? '新增' : '編輯'}商品模組`

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={productModelListRoute}>商品模組列表</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{title}</Breadcrumb.Item>
      </Breadcrumb>
      <Typography.Title level={2} style={{ marginTop: '20px' }}>
        {title}
      </Typography.Title>
    </>
  )
}

export default ProductModelEditorBreadcrumb
