import { ColumnsType } from 'antd/es/table/interface'
import { Table, Tag } from 'antd'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Interval from './Interval'
import Actions from './Actions'
import Tags from './Tags'
import DraggableBodyRow from './DraggableBodyRow'
import update from 'immutability-helper'
import { useNavigate } from 'react-router'
import { useMutation, useQuery } from '@apollo/client'
import { GetCategories } from '../../../apollo-types/getCategories'
import { SetCategory, SetCategoryVariables } from '../../../apollo-types/setCategory'
import { GET_CATEGORIES, SET_CATEGORY } from '../../CategoryGQL'

import React, { useState, useCallback } from 'react'
import './index.css'

const CategoryListTable = ({ type }: any) => {
  const navigate = useNavigate()
  const { data, loading, error, refetch } = useQuery<GetCategories>(GET_CATEGORIES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      categoryType: 'EVENT',
      categoryEnableType: 'ALL',
    },
  })

  if (error) {
    navigate('/error')
    return null
  }

  const categoryList = data?.categories ? data.categories : []
  const moveRow = (dragIndex: number, hoverIndex: number) => {}

  return (
    <DndProvider backend={HTML5Backend}>
      <Table
        id="draggable-product-model-list"
        rowKey="id"
        columns={columns}
        dataSource={categoryList.filter((d) => d?.type === type)}
        components={components}
        bordered
        onRow={(_, index) =>
          ({
            index,
            moveRow,
          } as any)
        }
      />
    </DndProvider>
  )
}

export default CategoryListTable

const components = {
  body: {
    row: DraggableBodyRow,
  },
}

const columns: ColumnsType<any> = [
  {
    title: '狀態',
    dataIndex: 'enabled',
    key: 'enabled',
    render: (t, record) => {
      const startTime = !record?.startTime ? undefined : record.startTime
      const endTime = !record?.endTime ? undefined : record.endTime
      const hasInterval = startTime || endTime
      const timeIsInvalid =
        new Date(startTime).getTime() <= Date.now() && Date.now() <= new Date(endTime).getTime()
      const isEnabled = t && (!hasInterval || timeIsInvalid)
      return <Tag color={isEnabled ? 'green' : 'gold'}>{isEnabled ? '上線中' : '未上線'}</Tag>
    },
  },
  {
    title: '分類名稱',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '分類路徑',
    dataIndex: 'id',
    key: 'id',
    render: (route) => <div>/{route}</div>,
  },
  {
    title: '有效區間',
    dataIndex: 'validInterval',
    key: 'validInterval',
    render: (_, record) => <Interval interval={[record.startTime, record.endTime]} />,
  },
  {
    title: '標籤',
    dataIndex: 'hashTags',
    key: 'hashTags',
    render: (tags) => <Tags tags={tags} />,
  },
  {
    title: '操作',
    dataIndex: 'action',
    render: (_, record) => <Actions {...record} />,
  },
]
