import React from 'react'
import { Tag, Input } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

interface EditableTagGroup {
  value?: { id: string }[]
  onChange?: Function
}

export default function EditableTagGroup({ value: tags = [], onChange }: EditableTagGroup) {
  const [inputVisible, setInputVisible] = React.useState(false)
  const [inputValue, setInputValue] = React.useState('')

  const handleClose = (removedTagId: string) => {
    const currentTags = tags.filter((tag) => tag.id !== removedTagId)
    onChange?.(currentTags)
  }

  const showInput = () => {
    setInputVisible(true)
  }

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value)
  }

  const handleInputConfirm = () => {
    if (inputValue && !tags.find((tag) => tag.id === inputValue)) {
      const currentTags = [...tags, { id: inputValue }]
      onChange?.(currentTags)
    }
    setInputVisible(false)
    setInputValue('')
  }

  const saveInputRef = React.useRef<any>()
  React.useEffect(() => {
    if (inputVisible) {
      saveInputRef.current?.focus?.()
    }
  }, [inputVisible])

  return (
    <div className="space-y-1">
      {tags.map((tag) => (
        <Tag key={tag.id} className="edit-tag" closable onClose={() => handleClose(tag.id)}>
          {tag.id}
        </Tag>
      ))}
      {inputVisible && (
        <Input
          ref={saveInputRef}
          type="text"
          size="small"
          className="w-40"
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      )}
      {!inputVisible && (
        <Tag className="site-tag-plus" onClick={showInput}>
          <PlusOutlined /> 新增文章
        </Tag>
      )}
    </div>
  )
}
