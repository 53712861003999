import React from 'react'
import { Outlet } from 'react-router-dom'
import DashboardLayout from './components/DashboardLayout'

export default function Dashboard() {
  return (
    <DashboardLayout>
      <Outlet />
    </DashboardLayout>
  )
}
