import React from 'react'
// prettier-ignore
import { Button, Upload, message } from 'antd'
// prettier-ignore
import { UploadOutlined } from '@ant-design/icons'
import { useImageUpload } from 'src/daily/daily.service'

interface ImageUploaderProps {
  onChange?: Function
  value?: any
}

export default function ImageUploader({ value, onChange }: ImageUploaderProps) {
  const [file, setFile] = React.useState<any>(value ? {
    uid: Date.now().toString(),
    name: value,
    imageUrl: value,
    thumbUrl: process.env.REACT_APP_IMAGE_BASEURL + value
  } : undefined)
  const [isLoading, toggleLoading] = React.useState(false)

  const uploadImage = useImageUpload()
  React.useEffect(() => {
    if (!file) return
    if (isLoading === false && file instanceof File) {
      toggleLoading(true)
      uploadImage(file)
        .then((result) => {
          onChange?.(result.imageUrl)
          setFile((info: any) => ({ ...info, ...result }))
          message.success('圖片上傳成功！')
        })
        .finally(() => {
          toggleLoading(false)
        })
    }
  }, [file, isLoading, uploadImage, onChange])

  return (
    <Upload
      listType="picture"
      // limit to 1 image
      fileList={file ? [file] : []}
      // Disable auto uploading images
      beforeUpload={() => false}
      onChange={(info) => {
        if (info.file.status !== 'removed') {
          setFile(info.file)
        }
      }}
      onRemove={() => setFile(null)}
    >
      <Button loading={isLoading} disabled={Boolean(file)}>
        <UploadOutlined /> 上傳
      </Button>
    </Upload>
  )
}
