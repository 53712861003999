import React, { useState } from 'react'
import Header from './CategoryListHeader'
import Table from './CategoryListTable'
import { Tabs } from 'antd'

const { TabPane } = Tabs

const CategoryList = () => {
  const [curTab, setCurTab] = useState('event')

  const handleTabChange = (key: string) => {
    setCurTab(key)
  }

  return (
    <>
      <Header curTab={curTab} />
      <Tabs defaultActiveKey={curTab} onChange={handleTabChange}>
        <TabPane tab="活動" key="event">
          <Table type={curTab} />
        </TabPane>
        <TabPane tab="主要" key="main">
          <Table type={curTab} />
        </TabPane>
      </Tabs>
    </>
  )
}

export default CategoryList
