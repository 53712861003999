import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Typography, Input, notification } from 'antd'
import { useQuery, useMutation } from '@apollo/client'
import { GET_CATEGORIES, SET_CATEGORY } from '../_core/graphOperation'

interface ManuallyAddArticlesPanelProps {
  categoryData: CategoryData
}

interface CategoryData {
  id?: string
  slug?: string
  name: string
  enabled: boolean
  creator: boolean
  editor: boolean
  interest: boolean
  postID?: string
}

const ManuallyAddArticlesPanel = () => {
  const [categoryList, setCategoryList] = useState<any[]>([])
  const { data } = useQuery(GET_CATEGORIES, {
    variables: {
      categoryType: 'SYSTEM',
      categoryEnableType: 'ALL',
    },
  })

  useEffect(() => {
    if (data) {
      setCategoryList(data.categories)
    }
  }, [data])

  return (
    <Row>
      <Col span={12}>
        <Typography.Title level={2}>熱門排行榜人工加文章</Typography.Title>
      </Col>
      <Col span={24}>
        {categoryList.map((categoryData, index) => (
          <ClassificationInputPanel key={index} categoryData={categoryData} />
        ))}
      </Col>
    </Row>
  )
}

const ClassificationInputPanel = (props: ManuallyAddArticlesPanelProps) => {
  const { categoryData } = props
  const { id, name, enabled, creator, editor, interest, postID: originalPostId } = categoryData
  const [setCategory, { loading }] = useMutation(SET_CATEGORY, {
    onError: () => notification.error({ message: '儲存失敗' }),
    onCompleted: () => {
      notification.success({ message: '儲存成功' })
      setIsChange(false)
    },
  })
  const [postId, setPostID] = useState(originalPostId ? originalPostId : '')
  const [isChange, setIsChange] = useState(false)

  const onChangePostId = (value: string) => {
    setPostID(value)
    setIsChange(true)
  }

  const onClickSetPostId = () => {
    setCategory({
      variables: {
        input: {
          slug: id,
          name,
          enabled,
          creator,
          editor,
          interest,
          postID: postId,
        },
      },
    })
  }

  return (
    <Row gutter={[16, 16]}>
      <Col span={3}>
        <Typography.Title level={5}>{name}</Typography.Title>
      </Col>
      <Col span={5}>
        <Input
          placeholder="請輸入 Post Id"
          value={postId}
          onChange={(e) => onChangePostId(e.target.value)}
        />
      </Col>
      <Col span={4}>
        <Button
          type="primary"
          disabled={!isChange}
          onClick={() => onClickSetPostId()}
          loading={loading}
        >
          儲存
        </Button>
      </Col>
    </Row>
  )
}

export default ManuallyAddArticlesPanel
