import React from 'react'
import { Form, Input, Button } from 'antd'

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
}

interface LoginFormProps {
  onSubmit?: (values: any) => void
  onSubmitFailed?: (errorInfo: any) => void
  loading?: boolean
}

export default function LoginForm({ onSubmit, onSubmitFailed, loading }: LoginFormProps) {
  return (
    <Form
      {...layout}
      className="w-1/2 text-center"
      name="admin"
      hideRequiredMark
      onFinish={onSubmit}
      onFinishFailed={onSubmitFailed}
    >
      <Form.Item
        label="帳號"
        name="username"
        rules={[{ required: true, message: '請輸入登入帳號' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="密碼"
        name="password"
        rules={[{ required: true, message: '請輸入登入密碼' }]}
      >
        <Input.Password />
      </Form.Item>

      <Button type="primary" htmlType="submit" size="large" loading={loading}>
        確認送出
      </Button>
    </Form>
  )
}
