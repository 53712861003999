import React from 'react'
import ProductModelEditorForm from './ProductModelEditorForm'
import { useQuery } from '@apollo/client'
import {
  GetPopSelectProductById,
  GetPopSelectProductByIdVariables,
} from '../../apollo-types/GetPopSelectProductById'
import { GET_POPSELECT_PRODUCT_BY_ID } from '../ProductGQL'
import { useNavigate, useParams } from 'react-router'

const ProductModelEdit = ({ mode }: ProductModelEditorProps) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { data, loading, error } = useQuery<
    GetPopSelectProductById,
    GetPopSelectProductByIdVariables
  >(GET_POPSELECT_PRODUCT_BY_ID, {
    fetchPolicy: 'network-only',
    variables: {
      getPopSelectProductByIdId: id,
    },
  })
  if (loading) {
    return null
  }

  if (error) {
    navigate('/error')
    return null
  }
  return <ProductModelEditorForm mode={mode} data={data?.getPopSelectProductById} />
}

const ProductModelCreate = ({ mode }: ProductModelEditorProps) => {
  return <ProductModelEditorForm mode={mode} />
}
interface ProductModelEditorProps {
  mode: ProductModelEditorFormMode
}
const ProductModelEditor = ({ mode }: ProductModelEditorProps) => {
  return mode === ProductModelEditorFormMode.CREATE ? (
    <ProductModelCreate mode={mode} />
  ) : (
    <ProductModelEdit mode={mode} />
  )
}

export default ProductModelEditor
export enum ProductModelEditorFormMode {
  CREATE = 'create',
  EDIT = 'edit',
}
