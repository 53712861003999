import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import zhTW from 'antd/es/locale/zh_TW'
import 'antd/dist/antd.css'
import './index.generated.css'
import { ApolloProvider } from '@apollo/client'
import { UserInfoProvider, useUserToken } from 'src/_contexts/user-info.context'
import getApolloClient from './getApolloClinet'

import App from './App'
import debug from 'debug'
import { ReactQueryDevtools } from 'react-query-devtools'

// Disable all debug logs by default
debug.disable()

const isDev = process.env.NODE_ENV === 'development'

const AppRouter = () => {
  const token = useUserToken()
  const client = getApolloClient({ token })

  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ApolloProvider>
  )
}

const Index = () => {
  return (
    <>
      <ConfigProvider locale={zhTW}>
        <UserInfoProvider>
          <AppRouter />
        </UserInfoProvider>
      </ConfigProvider>
      {isDev && <ReactQueryDevtools />}
    </>
  )
}

ReactDOM.render(<Index />, document.getElementById('root'))
