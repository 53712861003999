import React from 'react'
import { Input, Form } from 'antd'
import EditableTagGroup from './EditableTagGroup'
import { ListFieldMeta } from 'src/daily/daily.types'

interface DailyArticleGridTemplateProps {
  field: ListFieldMeta
}

export default function DailyArticleGridTemplate({ field }: DailyArticleGridTemplateProps) {
  const formFields = {
    title: {
      isListField: true,
      name: [field.name, 'data', 'title'],
      fieldKey: [field.fieldKey, 'data', 'title'],
      rules: [
        { required: true, message: '請輸入模組標題' },
        { max: 13, message: '最多13字' },
      ],
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
    },
    articles: {
      isListField: true,
      name: [field.name, 'data', 'articles'],
      fieldKey: [field.fieldKey, 'data', 'articles'],
      rules: [
        { required: true, message: '請輸入廣編文 ID' },
        { type: 'array', len: 4, message: '必填四組' },
      ],
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
    },
  }
  return (
    <>
      <Form.Item label="模組標題" {...formFields.title}>
        <Input placeholder="最多13字" />
      </Form.Item>
      {/*// @ts-ignore*/}
      <Form.Item label="貼文 ID" {...formFields.articles}>
        <EditableTagGroup />
      </Form.Item>
    </>
  )
}
