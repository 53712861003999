type Categories = Record<string, { name: string; description: string; shortDescription: string }>

const CATEGORIES_CONFIG: Categories = {
  appall: {
    name: 'app首頁',
    description: '一打開 app 的地方',
    shortDescription: '一打開 app 的地方',
  },
  weball: {
    name: 'web首頁',
    description: 'web 首頁',
    shortDescription: 'web 首頁',
  },
  beauty: {
    name: '妝漂亮',
    description:
      '水水女孩不可不知最新美妝保養情報指南！化妝、保養、髮型、美甲、瘦身，最夯的美容新知都在這裡！波波美妝讓愛美變成一種樂趣，由內而外妝點自己吧！',
    shortDescription: '最新美妝保養情報指南',
  },
  life: {
    name: '生活',
    description:
      '女孩們一起分享每一天的大小事、創造生活中的新鮮事！3C家電開箱、美劇、陸劇、台劇、電影，跟波波生活一起用生動的筆觸，跟上世界流行的步伐，紀錄最值得細細品味的女孩生活！',
    shortDescription: '蒐羅精彩生活大小事',
  },
  food: {
    name: '發胖',
    description:
      '吃貨女孩的美食清單！這裡沒有減肥只有美食，一起探索新奇美味，征服世界美食清單！蛋糕甜點、聚會餐廳、燒肉、美式漢堡、拉麵，各種台灣最新美食情報，都在波波發胖！',
    shortDescription: '超人氣美食必吃清單',
  },
  travel: {
    name: '打卡',
    description:
      '女孩出遊必備的旅行攻略！最新最夯的好玩景點、網美打卡點通通在這裡，不管是約會度假還是姐妹輕旅行，決定了就啟程，來趟說走就走的小旅行，跟著波波打卡的腳步放心出發吧！',
    shortDescription: '人氣景點出遊全攻略',
  },
  style: {
    name: '穿搭',
    description:
      '女孩的衣櫥風格千百種，每個都是最美的妳！時尚流行穿搭攻略讓妳快速掌握最新流行趨勢、必敗單品，找出屬於自己的風格，波波穿搭讓各路女孩們全都時髦到不行！',
    shortDescription: '每日造型潮流穿搭指南',
  },
  korea: {
    name: '泡菜',
    description:
      '韓系女孩必看！想去韓國吃喝玩樂、想發漏歐爸的最新動態、想知道韓妞的流行資訊，跟著波波泡菜的腳步走，就是永遠都走在最前面！韓星、韓劇、韓國美食通通不要錯過！',
    shortDescription: '韓國最新潮流文化情報',
  },
  japan: {
    name: '日本',
    description:
      '日系女孩必看！想去日本吃喝玩樂、發掘更多日本的新奇大小事，日本文化、日本旅遊、日本美食、日劇通通有，波波日本帶妳成為最鼠勾以的日本通！',
    shortDescription: '日本最新潮流文化情報',
  },
  constellation: {
    name: '星座',
    description:
      '女孩每日必看星座運勢！提供每日最新最快的星座運勢、星座配對、星座個性等有趣資訊，波波星座通通有，讓你成為最有魅力的幸福的女孩！',
    shortDescription: '每日運勢星座大解密',
  },
  love: {
    name: '邱比特',
    description:
      '波波邱比特不只上知天文，還很瞭女孩們的心！各種兩性問題、情侶間的私密話題、戀愛疑難雜症，只要跟著波波邱比特就能發現更多男女交往秘笈、告白技巧！',
    shortDescription: '戀愛粉紅泡泡大小事',
  },
  pet: {
    name: '寵物',
    description:
      '專屬毛孩寵物的超萌基地！替你蒐集所有能趕走憂鬱的可愛療癒能量波，無論是人類世界的小朋友、還是寵物世界的小明星貓貓狗狗，能讓你發笑的大小事都在波波寵物。',
    shortDescription: '毛孩趣事療癒妳每一天',
  },
  mama: {
    name: '媽咪兒',
    description:
      '波波媽咪兒集結所有媽咪最想知道的寶寶話題，新手媽媽必看！寶寶、懷孕、生產、婚姻、婆媳關係，媽咪生活大小事，就在波波媽咪通通一把抓！',
    shortDescription: '網美媽咪最夯寶寶話題',
  },
  entertainment: {
    name: '娛樂',
    description: '波波娛樂分享娛樂圈偶像每日新鮮事！為您奉上電影、影集最新情報消息！',
    shortDescription: '熱門電影影集偶像新鮮事',
  },
  sport: {
    name: '運動',
    description:
      '每一天都變成更好的自己！健康的體態透過每日穩定持續的運動健身來達成，各種健身、減重、瘦身、肌肉鍛鍊新知消息，波波運動都為你準備好了！',
    shortDescription: '減重健身打造更完美的妳',
  },
  diary: {
    name: '日記',
    description:
      '波波日記歡迎女孩們分享每日心情大小事，提供有趣的生活瑣事、語錄話題，更多生活暖心事等妳來分享！',
    shortDescription: '女孩的每一天都值得品味',
  },
  press: {
    name: '波波報',
    // duplicateName: '報報',
    description:
      '波波報報讓我們一起分享每天新鮮事！提供女孩生活好康的最新情報，為你朗讀世界改變的脈絡，創造最值得細細品味的女孩生活！',
    shortDescription: '最新的話題情報都在這',
  },
  trend: {
    name: '官方活動',
    description:
      '和波波一起生活吧！女孩們的吃喝玩樂、購物生活，快來和波波官方活動一起共襄盛舉！分享你的真實聲音、匯聚女孩們最真實的看法，通通都在波波官方活動！',
    shortDescription: '和波波一起生活！女孩們的吃喝玩樂購物生活都在這！',
  },
}

export default CATEGORIES_CONFIG
