import { ColumnsType } from 'antd/es/table/interface'
import { Table } from 'antd'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Categories from './Categories'
import Actions from './Actions'
import DraggableBodyRow from './DraggableBodyRow'

import React, { useCallback } from 'react'
import './index.css'
import { useMutation, useQuery } from '@apollo/client'
import { ListPopSelectProduct } from '../../../apollo-types/ListPopSelectProduct'
import {
  SetPopSelectProduct,
  SetPopSelectProductVariables,
} from '../../../apollo-types/SetPopSelectProduct'
import { LIST_POPSELECT_PRODUCT, SET_POPSELECT_PRODUCT } from 'src/productModel/ProductGQL'
import { SpinWrapper } from '../../../common'
import { useNavigate } from 'react-router'

const ProductModelListTable = () => {
  const navigate = useNavigate()
  const { data, loading, error, refetch } = useQuery<ListPopSelectProduct>(LIST_POPSELECT_PRODUCT, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  })
  const [setPopSelectProduct, { loading: mutationLoading }] = useMutation<
    SetPopSelectProduct,
    SetPopSelectProductVariables
  >(SET_POPSELECT_PRODUCT, {
    onCompleted: () => {
      refetch()
    },
  })

  const productModelList = data?.listPopSelectProduct ? data.listPopSelectProduct : []

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = productModelList[dragIndex]
      setPopSelectProduct({
        variables: {
          setPopSelectProductId: dragRow?.id as string,
          input: {
            order: hoverIndex,
          },
        },
      })
    },
    [productModelList],
  )

  if (error) {
    navigate('/error')
    return null
  }

  return (
    <SpinWrapper tip="加載中" isLoading={loading || mutationLoading}>
      <DndProvider backend={HTML5Backend}>
        <Table
          id="draggable-product-model-list"
          rowKey="id"
          columns={columns}
          dataSource={productModelList}
          components={components}
          pagination={{
            defaultPageSize: 100,
          }}
          onRow={(_, index) =>
            ({
              index,
              moveRow,
            } as any)
          }
          bordered
        />
      </DndProvider>
    </SpinWrapper>
  )
}

export default ProductModelListTable

const components = {
  body: {
    row: DraggableBodyRow,
  },
}

// TODO: waiting for api to get type
const columns: ColumnsType<any> = [
  {
    title: '標題',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: '說明文字',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: '分類',
    dataIndex: 'clas',
    key: 'clas',
    render: (text) => <Categories text={text} />,
  },
  {
    title: '商品',
    dataIndex: 'productNumber',
    key: 'productNumber',
    render: (_, record) => record.urls?.length ?? 0,
  },
  {
    title: '操作',
    dataIndex: 'action',
    render: (_, record) => <Actions {...record} />,
  },
]
