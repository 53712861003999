import { gql } from '@apollo/client'

export const SET_POPSELECT_PRODUCT = gql`
  mutation SetPopSelectProduct($setPopSelectProductId: ID!, $input: SetPopSelectProductInput!) {
    setPopSelectProduct(id: $setPopSelectProductId, input: $input) {
      id
    }
  }
`

export default SET_POPSELECT_PRODUCT
