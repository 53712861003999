import { gql } from '@apollo/client'

const CREATE_POPSELECT_PRODUCT = gql`
  mutation CreatePopSelectProduct($input: CreatePopSelectProductInput!) {
    createPopSelectProduct(input: $input) {
      id
    }
  }
`

export default CREATE_POPSELECT_PRODUCT
