import React from 'react'
import { Tag } from 'antd'

const Categories = ({ tags }: any) => {
  if (!tags) {
    return null
  }
  return (
    <div>
      {tags.map((t: string) => (
        <Tag key={t}>{t}</Tag>
      ))}
    </div>
  )
}

export default Categories
