import React from 'react'
// prettier-ignore
import { Input, Form, Card, Button } from 'antd'
// prettier-ignore
import { ArrowUpOutlined, ArrowDownOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { ListFieldMeta } from 'src/daily/daily.types'
import ImageUploader from './ImageUploader'

interface DailyEventTemplateProps {
  field: ListFieldMeta
}

export default function DailyEventTemplate({ field }: DailyEventTemplateProps) {
  const formFields = {
    title: {
      isListField: true,
      name: [field.name, 'data', 'title'],
      fieldKey: [field.fieldKey, 'data', 'title'],
      rules: [{ required: true, message: '請輸入模組標題' }],
    },
    events: {
      name: [field.name, 'data', 'eventItems'],
    },
  }
  return (
    <>
      <Form.Item label="模組標題" {...formFields.title}>
        <Input />
      </Form.Item>
      <Form.List {...formFields.events}>
        {(fields, operations) => (
          <>
            {fields.map((field, idx) => (
              <Form.Item key={field.key} wrapperCol={{ offset: 4, span: 20 }}>
                <EventTemplate
                  field={field}
                  order={idx + 1}
                  moveUp={() => operations.move(idx, idx - 1)}
                  moveDown={() => operations.move(idx, idx + 1)}
                  remove={() => operations.remove(idx)}
                />
              </Form.Item>
            ))}
            <Form.Item wrapperCol={{ offset: 4, span: 20 }}>
              <Button type="dashed" block onClick={() => operations.add()}>
                <PlusOutlined /> 新增活動
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  )
}

interface EventTemplateProps {
  order: number
  moveUp: () => void
  moveDown: () => void
  remove: () => void
  field: {
    name: number
    key: number
    fieldKey: number
  }
}

function EventTemplate({ order, moveUp, moveDown, remove, field }: EventTemplateProps) {
  const formFields = {
    description: {
      isListField: true,
      name: [field.name, 'description'],
      fieldKey: [field.fieldKey, 'description'],
      rules: [{ required: true, message: '請輸入活動敘述' }],
    },
    cta: {
      isListField: true,
      name: [field.name, 'cta'],
      fieldKey: [field.fieldKey, 'cta'],
      rules: [{ required: true, message: '請輸入 CTA 文案' }],
    },
    url: {
      isListField: true,
      name: [field.name, 'url'],
      fieldKey: [field.fieldKey, 'url'],
      rules: [{ required: true, message: '請輸入活動連結' }],
    },
    image: {
      isListField: true,
      name: [field.name, 'image'],
      fieldKey: [field.fieldKey, 'image'],
      rules: [{ required: true, message: '請上傳活動圖片' }],
    },
  }

  const title = `活動 ${order}`

  return (
    <Card
      type="inner"
      title={title}
      actions={[
        <ArrowUpOutlined onClick={moveUp} />,
        <ArrowDownOutlined onClick={moveDown} />,
        <DeleteOutlined onClick={remove} />,
      ]}
    >
      <Form.Item label="活動敘述" {...formFields.description}>
        <Input />
      </Form.Item>
      <Form.Item label="CTA 文案" {...formFields.cta}>
        <Input />
      </Form.Item>
      <Form.Item label="活動連結" {...formFields.url}>
        <Input />
      </Form.Item>
      <Form.Item label="活動圖片" {...formFields.image}>
        <ImageUploader />
      </Form.Item>
    </Card>
  )
}
