import React from 'react'
// prettier-ignore
import {Button, Card, Col, DatePicker, Divider, Form, Input, message, Row, Select, Space, Typography} from 'antd'
// prettier-ignore
import {ArrowDownOutlined, ArrowUpOutlined, DeleteOutlined, PlusOutlined} from '@ant-design/icons'
import DailyPgcTemplate from './DailyPgcTemplate'
import DailyEventTemplate from './DailyEventTemplate'
import DailyArticleGridTemplate from './DailyArticleGridTemplate'
import { useNavigate, useParams } from 'react-router'
import { navigatePath } from 'src/_constants/routes'
import { DailyModuleFormInputs, ListFieldMeta } from 'src/daily/daily.types'
import { DailyComponentType } from 'src/daily/daily.constants'
import { formatDailyModuleToFormInputs } from 'src/daily/daily.utils'
import { useDailyModuleDetail, useDailyModuleEditorSubmit } from 'src/daily/daily.service'
import { FormInstance } from 'antd/lib/form'

const EmptyTemplate = () => null

const templates: Record<string, React.ComponentType<{ field: ListFieldMeta }>> = {
  [DailyComponentType.PGC]: DailyPgcTemplate,
  [DailyComponentType.EVENT]: DailyEventTemplate,
  [DailyComponentType.ARTICLE_GRID]: DailyArticleGridTemplate,
}

export default function DailyModuleEditor({ mode }: { mode: string }) {
  const { id: moduleId } = useParams()
  const navigate = useNavigate()
  const editing = useDailyModuleDetail(moduleId)
  const initialFormValues = editing ? formatDailyModuleToFormInputs(editing) : undefined
  const [submit, isLoading] = useDailyModuleEditorSubmit(editing)

  async function submitDailyModule(info: DailyModuleFormInputs) {
    if (!info.components || info.components?.length < 1) {
      message.error('請至少新增一個模組')
      return
    }
    try {
      await submit(info)
      navigate(navigatePath.daily)
    } catch (error) {
      message.error(error.message)
    }
  }

  const [form] = Form.useForm()
  const goBack = () => {
    if (form.isFieldsTouched() === true) {
      const confirmed = window.confirm('確定要取消，並返回列表？已編輯的內容不會儲存哦！')
      if (confirmed === false) return
    }
    navigate(navigatePath.daily)
  }

  if (moduleId && !editing) return null

  return (
    <DailyModuleEditorForm
      form={form}
      initialValues={initialFormValues}
      mode={mode}
      moduleId={moduleId}
      isLoading={isLoading}
      onCancel={goBack}
      onSubmit={submitDailyModule}
    />
  )
}

interface DailyModuleEditorFormProps {
  form?: FormInstance
  initialValues?: any
  mode: string
  moduleId?: string
  isLoading?: boolean
  onCancel: (...args: any[]) => void
  onSubmit: (...args: any[]) => void
}

export function DailyModuleEditorForm({
  form,
  initialValues,
  mode,
  moduleId,
  isLoading,
  onCancel,
  onSubmit,
}: DailyModuleEditorFormProps) {
  const formFields = {
    schedule: {
      name: 'schedule',
      rules: [{ required: true, message: '請輸入排程' }],
      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
    },
    title: {
      name: 'title',
      rules: [{ required: true, message: '請輸入標題' }],
      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
    },
    description: {
      name: 'description',
      rules: [{ required: true, message: '請輸入敘述' }],
      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
    },
    components: {
      name: 'components',
    },
    componentTemplate: (field: { name: number; fieldKey: number }) => ({
      isListField: true,
      name: [field.name, 'template'],
      fieldKey: [field.fieldKey, 'template'],
    }),
  }
  return (
    <Form
      form={form}
      name="daily"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 20 }}
      hideRequiredMark
      initialValues={initialValues}
      // onValuesChange={console.info}
      // onFinish={console.info}
      // @ts-ignore: antd typing is not extendable, annoying
      onFinish={onSubmit}
    >
      <Row>
        <Col span={12}>
          <Typography.Title level={2}>Daily 模組 - {mode}</Typography.Title>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item label="ID">
            <span>{moduleId}</span>
          </Form.Item>
        </Col>
        <Col span={12} className="text-right">
          <Space>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              儲存
            </Button>
            <Button danger onClick={onCancel}>
              取消，返回列表
            </Button>
          </Space>
        </Col>
      </Row>
      <Form.Item label="排程" {...formFields.schedule} style={{ margin: 0 }}>
        <DatePicker.RangePicker showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm" />
      </Form.Item>
      <Divider />
      <div className="space-y-4">
        <Form.Item label="標題" {...formFields.title}>
          <Input />
        </Form.Item>
        <Form.Item label="敘述" {...formFields.description}>
          <Input />
        </Form.Item>
        <Form.List {...formFields.components}>
          {(fields, operations) => (
            <>
              <Form.Item wrapperCol={{ span: 24 }}>
                <Button type="dashed" block onClick={() => operations.add({}, 0)}>
                  <PlusOutlined /> 新增模組
                </Button>
              </Form.Item>
              {fields.map((field, idx) => (
                <Card
                  key={field.key}
                  title={
                    <Form.Item
                      {...formFields.componentTemplate(field)}
                      label={`#${idx + 1}`}
                      labelAlign="left"
                      colon={false}
                      style={{ margin: 0 }}
                    >
                      <Select placeholder="選擇模組" className="w-full">
                        <Select.Option value={DailyComponentType.PGC}>PGC 模組</Select.Option>
                        <Select.Option value={DailyComponentType.EVENT}>活動模組</Select.Option>
                        <Select.Option value={DailyComponentType.ARTICLE_GRID}>
                          貼文模組
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  }
                  actions={[
                    <ArrowUpOutlined onClick={() => operations.move(idx, idx - 1)} />,
                    <ArrowDownOutlined onClick={() => operations.move(idx, idx + 1)} />,
                    <DeleteOutlined onClick={() => operations.remove(idx)} />,
                  ]}
                >
                  <Form.Item
                    noStyle
                    wrapperCol={{ span: 24 }}
                    shouldUpdate={(prev, current) =>
                      prev.components[field.name]?.template !==
                      current.components[field.name]?.template
                    }
                  >
                    {({ getFieldValue }) => {
                      const Template =
                        templates[
                          getFieldValue([
                            formFields.components.name,
                            ...formFields.componentTemplate(field).name,
                          ])
                        ] || EmptyTemplate
                      return <Template field={field} />
                    }}
                  </Form.Item>
                </Card>
              ))}
            </>
          )}
        </Form.List>
      </div>
    </Form>
  )
}
