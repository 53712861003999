import React from 'react'
import Header from './ProductModelListHeader'
import Table from './ProductModelListTable'

const ProductModelList = () => {
  return (
    <>
      <Header />
      <Table />
    </>
  )
}

export default ProductModelList
