import React from 'react'
import { Space, Button } from 'antd'
import { Link } from 'react-router-dom'
import { routeConfig } from '../../../_constants/routes'
import { useNavigate } from 'react-router'
import { useMutation, useQuery } from '@apollo/client'
import { GetCategories } from '../../../apollo-types/getCategories'
import { SetCategory, SetCategoryVariables } from '../../../apollo-types/setCategory'
import { DELETE_CATEGORY } from '../../CategoryGQL'
import moment from 'moment'
import { DeleteCategory, DeleteCategoryVariables } from 'src/apollo-types/DeleteCategory'
import { useCallback } from 'react'

const Actions = ({ id }: Pick<any, 'id'>) => {
  const navigate = useNavigate()
  const [deleteCategory, {}] = useMutation<DeleteCategory, DeleteCategoryVariables>(
    DELETE_CATEGORY,
    {
      onCompleted: () => {
        window.location.reload()
      },
    },
  )

  const handleRemove = () => {
    if (!id) return
    const confirmed = window.confirm(`刪除 id=${id}？`)
    if (!confirmed) return
    deleteCategory({
      variables: {
        slug: id,
      },
    })
  }

  const editRoute =
    routeConfig.root.dashboard + routeConfig.dashboard.category + routeConfig.category.edit

  return (
    <Space size="middle">
      <Link to={editRoute.replace(':id', id)}>
        <Button type="primary">編輯</Button>
      </Link>
      <Button danger onClick={handleRemove}>
        刪除
      </Button>
    </Space>
  )
}

export default Actions
