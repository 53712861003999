import React from 'react'
import { useQuery, useMutation, queryCache } from 'react-query'
import { FetchFactory } from 'src/_utils/fetch-utils'
import { useUserToken } from 'src/_contexts/user-info.context'

interface FbVideo {
  htmlCode: string
}

const FB_VIDEO_QUERY_KEY = 'fb-video'

export function useFbVideo() {
  const userToken = useUserToken()
  const request = new FetchFactory({ userToken })

  const { data, status } = useQuery<FbVideo, string>(
    FB_VIDEO_QUERY_KEY,
    () => request.get(`/v2/fb_video_content`),
    {
      staleTime: Infinity,
      cacheTime: 0,
    },
  )

  return [data, status] as const
}

export function useFbVideoSubmit() {
  const userToken = useUserToken()
  const request = new FetchFactory({ userToken })
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [putFbVideo] = useMutation<void, FbVideo, FbVideo>(
    async ({ htmlCode }) => {
      setIsSubmitting(true)
      try {
        await request.put(`/v2/popadmin/fb_video_content/`, {
          htmlCode,
        })
      } finally {
        setIsSubmitting(false)
      }
    },
    {
      onSuccess() {
        queryCache.refetchQueries(FB_VIDEO_QUERY_KEY, { exact: true })
      },
    },
  )

  return [isSubmitting, putFbVideo] as const
}
