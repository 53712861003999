import React from 'react'
import { FetchFactory, postJSON } from 'src/_utils/fetch-utils'
import { useUserToken, useUserMid, useValidated } from 'src/_contexts/user-info.context'
import { useQuery, useMutation, queryCache } from 'react-query'
import type { DailyModule, DailyModuleFormInputs } from './daily.types'
import { DailyModuleState } from './daily.constants'
import { createLogger } from 'src/_utils/logger'

const logger = createLogger({ namespace: 'daily.service', logLevel: 'warn' })

export const DAILY_MODULES_QUERY_KEY = 'daily-modules'

export function useDailyModuleDetail(moduleId: string) {
  const userToken = useUserToken()
  const request = new FetchFactory({ userToken })
  const { data } = useQuery<DailyModule, [string, string]>(
    [DAILY_MODULES_QUERY_KEY, moduleId],
    () => (moduleId ? request.get(`/v2/popadmin/daily_modules/${moduleId}`) : Promise.resolve()),
    {
      staleTime: Infinity,
      cacheTime: 0,
    },
  )
  return data
}

export function useDailyModuleEditorSubmit(editing?: any) {
  const mid = useUserMid()
  const [isLoading, setIsLoading] = React.useState(false)
  const createDailyModule = useCreateDailyModule()
  const updateDailyModule = useUpdateDailyModule()

  async function update(info: DailyModuleFormInputs) {
    setIsLoading(true)
    const error = await updateDailyModule({
      ...editing,
      startTime: info.schedule?.[0]?.utc().format() ?? null,
      endTime: info.schedule?.[1]?.utc().format() ?? null,
      updatedBy: mid ?? '',
      title: info.title,
      description: info.description,
      components: info.components,
    })
    setIsLoading(false)
    if (error) throw new Error('儲存失敗，請稍候再試一次')
  }

  async function create(info: DailyModuleFormInputs) {
    setIsLoading(true)
    const error = await createDailyModule({
      state: DailyModuleState.PUBLISHED,
      startTime: info.schedule?.[0]?.utc().format() ?? null,
      endTime: info.schedule?.[1]?.utc().format() ?? null,
      updatedBy: mid ?? '',
      title: info.title,
      description: info.description,
      components: info.components,
    })
    setIsLoading(false)
    if (error) throw new Error('儲存失敗，請稍候再試一次')
  }

  const submit = React.useCallback(editing ? update : create, [editing])

  return [submit, isLoading] as const
}

export function useDailyModuleListItems() {
  const validated = useValidated()
  const userToken = useUserToken()
  const request = new FetchFactory({ userToken })
  const { data, status, error, refetch } = useQuery<DailyModule[], string>(
    DAILY_MODULES_QUERY_KEY,
    () => request.get('/v2/popadmin/daily_modules'),
    {
      enabled: false,
      retry: false,
    },
  )
  React.useEffect(() => {
    if (validated) {
      refetch()
    }
  }, [validated, refetch])
  const emptyItems = React.useRef([])
  const items = status === 'success' ? data || emptyItems.current : emptyItems.current
  return [items, status, error] as const
}

export function useCreateDailyModule() {
  const userToken = useUserToken()
  const request = new FetchFactory({ userToken })
  const [postDailyModule] = useMutation<any, DailyModule, DailyModule>(
    (payload) => request.post('/v2/popadmin/daily_modules', payload),
    {
      onSuccess() {
        queryCache.refetchQueries(DAILY_MODULES_QUERY_KEY, { exact: true })
      },
    },
  )
  return React.useCallback(
    async (module: DailyModule) => {
      try {
        await postDailyModule(module)
        return null
      } catch (error) {
        logger.log(error)
        return error
      }
    },
    [postDailyModule],
  )
}

export function useUpdateDailyModule() {
  const userToken = useUserToken()
  const request = new FetchFactory({ userToken })
  const [putDailyModule] = useMutation<any, DailyModule, DailyModule>(
    ({ id, ...payload }) => request.put(`/v2/popadmin/daily_modules/${id}`, payload),
    {
      onSuccess() {
        queryCache.refetchQueries(DAILY_MODULES_QUERY_KEY, { exact: true })
      },
    },
  )
  return React.useCallback(
    async (payload: DailyModule) => {
      try {
        await putDailyModule(payload)
        return null
      } catch (error) {
        logger.log(error)
        return error
      }
    },
    [putDailyModule],
  )
}

export function useRemoveDailyModule() {
  const userToken = useUserToken()
  const request = new FetchFactory({ userToken })
  const [deleteDailyModule] = useMutation<any, string, string>(
    (moduleId) => request.delete(`/v2/popadmin/daily_modules/${moduleId}`),
    {
      onSuccess() {
        queryCache.refetchQueries(DAILY_MODULES_QUERY_KEY, { exact: true })
      },
    },
  )
  return React.useCallback(
    async (removedId: string) => {
      try {
        await deleteDailyModule(removedId)
        return null
      } catch (error) {
        logger.log(error)
        return error
      }
    },
    [deleteDailyModule],
  )
}

export function useImageUpload() {
  const token = useUserToken()
  return React.useCallback(
    (file: File) => {
      if (!token) return Promise.reject(new Error('登入狀態異常，請重新登入'))
      const data = new FormData()
      data.append('img', file)
      data.append('token', token)
      const fileName = file.name
      return postJSON('/uploadImg', data).then(([thumbnail, image]) => {
        if (thumbnail.code === 16000 && image.code === 16000) {
          return {
            name: fileName,
            imageUrl: image.res,
            thumbUrl: process.env.REACT_APP_IMAGE_BASEURL + thumbnail.res,
          }
        }
        throw new Error(`上傳圖片失敗：${thumbnail.msg}`)
      })
    },
    [token],
  )
}
