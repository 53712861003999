/**
 * @see https://www.figma.com/file/vpF52Wk52saQhH42whhV7A/AppDaily-Spec?node-id=36%3A1006
 */
export enum DailyComponentType {
  /**
   * PGC 模組
   */
  PGC = 'pgc',

  /**
   * 活動模組
   */
  EVENT = 'event',

  /**
   * 貼文模組
   */
  ARTICLE_GRID = 'articlesGrid',
}

export enum DailyModuleEditorMode {
  CREATE = 'create',
  EDIT = 'edit',
}

export enum DailyModuleState {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  DELETED = 'deleted',
}

export enum DailyModuleStateLabel {
  /**
   * 草稿：尚未編輯完成的模組，部分欄位可留空
   */
  DRAFT = 'draft',

  /**
   * 排程中：尚未進入排程時間的模組
   */
  SCHEDULED = 'scheduled',

  /**
   * 已發布：目前正在排程時間內的模組
   */
  PUBLISHED = 'published',

  /**
   * 已結束：排程時間已過的模組
   */
  DONE = 'done',
}
