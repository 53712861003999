import moment from 'moment'
import type { DailyModule } from './daily.types'

export const formatDailyModuleToFormInputs = (editing: DailyModule) => {
  return {
    ...editing,
    schedule:
      editing.startTime && editing.endTime
        ? [moment(editing.startTime), moment(editing.endTime)]
        : undefined,
  }
}
