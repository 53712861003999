import { ApolloClient, InMemoryCache, createHttpLink, from } from '@apollo/client'

import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
    )
  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPH_API_BASEURL,
  credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
})

const getAuthLink = (token?: string) =>
  setContext((_, { headers }) => {
    if (!token) return { headers }

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: `Popdaily ${token}`,
      },
    }
  })

interface GetApolloClientArgs {
  token?: string
}

const getApolloClient = ({ token }: GetApolloClientArgs) =>
  new ApolloClient({
    link: from([errorLink, getAuthLink(token), httpLink]),
    cache: new InMemoryCache(),
  })

export default getApolloClient
