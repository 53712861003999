import React from 'react'
import LoginLayout from './components/LoginLayout'
import LoginForm from './components/LoginForm'

import { loginWithEmail } from './login.service'
import { useUserInfoContext, UserInfo } from 'src/_contexts/user-info.context'
import { useNavigate } from 'react-router'

interface LoginProps {
  redirectTo: string
}

function Login({ redirectTo }: LoginProps) {
  const { userInfo, setUserInfo } = useUserInfoContext()
  const navigate = useNavigate()
  const [pending, setPending] = React.useState(false)

  React.useEffect(() => {
    if (userInfo) {
      navigate(redirectTo)
    }
  }, [userInfo, navigate, redirectTo])

  async function handleSubmit(values: any) {
    setPending(true)
    try {
      const info: UserInfo = await loginWithEmail(values)
      setUserInfo(info)
      window.alert('登入成功')
    } catch (exception) {
      window.alert((exception as Error).message)
    } finally {
      setPending(false)
    }
  }

  return (
    <LoginLayout>
      <LoginForm
        onSubmit={handleSubmit}
        loading={pending}
      />
    </LoginLayout>
  )
}

export default Login
