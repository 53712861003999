import React from 'react'
import { Tag } from 'antd'
import CATEGORIES_CONFIG from 'src/productModel/CategoriesConfig'

// TODO: waiting for api to fix type
const Categories = ({ text }: any) => {
  return (
    <div>
      {text.map((t: string) => (
        <Tag key={t}>{CATEGORIES_CONFIG[t].name}</Tag>
      ))}
    </div>
  )
}

export default Categories
