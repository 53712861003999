import { gql } from '@apollo/client'

const GET_POPSELECT_PRODUCT_BY_ID = gql`
  query GetPopSelectProductById($getPopSelectProductByIdId: ID!) {
    getPopSelectProductById(id: $getPopSelectProductByIdId) {
      id
      title
      categoryUrl
      description
      clas
      urls
    }
  }
`

export default GET_POPSELECT_PRODUCT_BY_ID
