import { gql } from '@apollo/client'

const LIST_POPSELECT_PRODUCT = gql`
  query ListPopSelectProduct {
    listPopSelectProduct {
      id
      title
      description
      clas
      urls
    }
  }
`

export default LIST_POPSELECT_PRODUCT
