import React from 'react'
import { Layout, Menu, Dropdown } from 'antd'
import { SettingOutlined } from '@ant-design/icons'
import { Link, useLocation } from 'react-router-dom'
import { navigatePath } from 'src/_constants/routes'
import { useUserInfoContext } from 'src/_contexts/user-info.context'

const { Header, Content, Sider } = Layout

interface DashboardLayoutProps {
  children: JSX.Element
}

export default function DashboardLayout({ children }: DashboardLayoutProps) {
  const { userInfo, setUserInfo, setValidated } = useUserInfoContext()

  function logout() {
    const confirmed = window.confirm('確定登出？')
    if (confirmed === false) return
    setValidated(false)
    setUserInfo(null)
  }

  const [currentMenuKey, setCurrentMenuKey] = React.useState<string[]>()
  const location = useLocation()

  React.useEffect(() => {
    setCurrentMenuKey([location.pathname])
  }, [location])

  return (
    <Layout className="min-h-screen">
      <Header className="flex justify-between items-center px-8">
        <h1 className="text-gray-300 text-xl leading-normal font-bold">
          <Link to={navigatePath.index}>Popdaily Admin</Link>
        </h1>
        <Dropdown
          className="text-gray-300"
          overlay={
            <Menu>
              <Menu.Item key="1" onClick={logout}>
                登出
              </Menu.Item>
            </Menu>
          }
        >
          <span className="ant-dropdown-link flex items-center">
            Hi, {userInfo?.name ?? '訪客'} (mid: {userInfo?.mid ?? '---'}){' '}
            <SettingOutlined className="ml-2" />
          </span>
        </Dropdown>
      </Header>
      <Layout>
        <Sider className="text-gray-300">
          <Menu theme="dark" selectedKeys={currentMenuKey} mode="inline">
            <Menu.Item key={navigatePath.daily}>
              <Link to={navigatePath.daily}>Daily 模組</Link>
            </Menu.Item>
            <Menu.Item key={navigatePath.fbVideo}>
              <Link to={navigatePath.fbVideo}>FB 全站影片</Link>
            </Menu.Item>
            <Menu.Item key={'/others'}>
              <Link to={navigatePath.dashboard + '/others'}>其他模組</Link>
            </Menu.Item>
            <Menu.Item key={navigatePath.productModel}>
              <Link to={navigatePath.productModel}>商品模組</Link>
            </Menu.Item>
            <Menu.Item key={navigatePath.manuallyAddArticles}>
              <Link to={navigatePath.manuallyAddArticles}>人工加文章模組</Link>
            </Menu.Item>
            <Menu.Item key={navigatePath.category}>
              <Link to={navigatePath.category}>分類管理</Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <Content className="p-8 bg-gray-100">{children}</Content>
      </Layout>
    </Layout>
  )
}
