import React from 'react'

export function useLocalStorageState<T, K>(key: string, defaultValue: K) {
  const [state, setState] = React.useState<T | K>(() => {
    try {
      return JSON.parse(window.localStorage.getItem(key) || JSON.stringify(defaultValue))
    } catch (e) {
      return defaultValue
    }
  })

  React.useEffect(() => {
    if (!state && !window.localStorage.getItem(key)) {
      window.localStorage.removeItem(key)
      return
    }
    window.localStorage.setItem(key, JSON.stringify(state))
  }, [key, state])

  return [state, setState] as const
}
