import React from 'react'
import { Input, Form } from 'antd'
import EditableTagGroup from './EditableTagGroup'
import { ListFieldMeta } from 'src/daily/daily.types'

interface DailyPgcTemplateProps {
  field: ListFieldMeta
}

export default function DailyPgcTemplate({ field }: DailyPgcTemplateProps) {
  const formFields = {
    title: {
      isListField: true,
      name: [field.name, 'data', 'title'],
      fieldKey: [field.fieldKey, 'data', 'title'],
      rules: [{ required: true, message: '請輸入模組標題' }],
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
    },
    pgcItems: {
      isListField: true,
      name: [field.name, 'data', 'pgcItems'],
      fieldKey: [field.fieldKey, 'data', 'pgcItems'],
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      extra: ['[選填] 輸入的文章 ID 將會依序於模組的第 4 則、第 7 則、第 10 則插入，多於三則時會隨機取三則顯示']
    },
  }
  return (
    <>
      <Form.Item label="模組標題" {...formFields.title}>
        <Input />
      </Form.Item>
      <Form.Item label="廣編文 ID" {...formFields.pgcItems}>
        <EditableTagGroup />
      </Form.Item>
    </>
  )
}
