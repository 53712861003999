import debug from 'debug'

interface CreateLoggerParams {
  namespace: string
  logLevel?: 'debug' | 'trace' | 'error' | 'info' | 'log' | 'warn'
  enabled?: boolean
}

export function createLogger ({ namespace, logLevel = 'debug', enabled = false }: CreateLoggerParams) {
  const logger = debug(namespace)
  if (enabled) {
    debug.enable(namespace)
  }
  logger.log = console[logLevel].bind(console)
  return logger
}
