import React from 'react'
import { Breadcrumb, Row, Col, Typography } from 'antd'
import { Link } from 'react-router-dom'
import { routeConfig } from '../../_constants/routes'
import { CategoryEditorMode } from './index'

const categoryListRoute = routeConfig.root.dashboard + routeConfig.dashboard.category

const CategoryEditorBreadcrumb = ({ mode }: { mode: CategoryEditorMode }) => {
  const title = `${mode === CategoryEditorMode.CREATE ? '新增' : '編輯'}分類`

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={categoryListRoute}>分類列表</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{title}</Breadcrumb.Item>
      </Breadcrumb>
      <Typography.Title level={2} style={{ marginTop: '20px' }}>
        {title}
      </Typography.Title>
    </>
  )
}

export default CategoryEditorBreadcrumb
