import { Spin } from 'antd'
import React, { ReactElement } from 'react'

interface ISpinWrapper {
  isLoading: boolean
  children: ReactElement
  tip: string
}

const SpinWrapper = ({ isLoading, children, tip }: ISpinWrapper) => {
  if (isLoading) {
    return <Spin tip={tip}>{children}</Spin>
  }

  return children
}

export default SpinWrapper
