import React from 'react'
import { Layout, Typography } from 'antd'

interface LoginLayoutProps {
  children: JSX.Element
}

export default function LoginLayout(props: LoginLayoutProps) {
  const { children } = props
  return (
    <Layout className="min-h-screen">
      <Layout.Content className="container mx-auto flex flex-col justify-center items-center">
        <Typography.Title className="text-center">登入</Typography.Title>
        {children}
      </Layout.Content>
    </Layout>
  )
}
