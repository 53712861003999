import { gql } from '@apollo/client'

const SET_CATEGORY = gql`
  mutation SetCategory($input: CategoryInput!) {
    setCategory(input: $input) {
      id
      name
    }
  }
`

export default SET_CATEGORY
