import { apiBaseUrl, fakeApiBaseUrl } from 'src/config'
import { createLogger } from 'src/_utils/logger'

const logger = createLogger({ namespace: 'fetch-utils' })

export async function getJSON(
  apiEndpointUrl: string,
  query?: Record<string, any> | null,
  headers?: HeadersInit,
) {
  let url = apiBaseUrl + apiEndpointUrl
  if (query) {
    url += `?${new URLSearchParams(query).toString()}`
  }
  const response = await window.fetch(url, {
    method: 'GET',
    headers,
  })
  return response.json()
}

export async function postJSON(apiEndpointUrl: string, body: FormData | string) {
  let url = apiBaseUrl + apiEndpointUrl
  const init: any = {
    method: 'POST',
    body,
  }
  const response = await window.fetch(url, init)
  return response.json()
}

export class FetchFactory {
  #apiUrl: string
  #agent: (url: string, options: RequestInit) => Promise<Response>

  constructor({
    userToken,
    useFakeApiBase = false,
  }: {
    userToken?: string
    useFakeApiBase?: boolean
  }) {
    const authHeader = userToken ? { Authorization: `Popdaily ${userToken}` } : null
    this.#apiUrl = useFakeApiBase ? fakeApiBaseUrl : apiBaseUrl
    this.#agent = (url: string, options: RequestInit) =>
      window.fetch(url, {
        ...options,
        headers: {
          ...authHeader,
          Accept: 'application/json',
          ...options.headers,
        },
      })
  }

  #transformQuery = (url: URL, query: Record<string, string> | null) => {
    if (!query) return
    for (const [name, value] of Object.entries(query)) {
      url.searchParams.append(name, value)
    }
  }

  async get(apiEndpointUrl: string, query: Record<string, string> | null = null) {
    const url = new URL(this.#apiUrl + apiEndpointUrl)
    url.searchParams.append('cid', 'admin')
    this.#transformQuery(url, query)
    logger.log(`GET ${url.href}`)
    const response = await this.#agent(url.href, {
      method: 'GET',
    })
    if (response.ok) {
      return response.json()
    }
    throw await response.json()
  }

  async post(apiEndpointUrl: string, body: Record<string, any>) {
    const url = new URL(this.#apiUrl + apiEndpointUrl)
    logger.log(`POST ${url.href}`)
    const response = await this.#agent(url.href, {
      method: 'POST',
      body: JSON.stringify({ ...body, cid: 'admin' }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (response.ok) {
      return response.text()
    }
    throw await response.json()
  }

  async put(apiEndpointUrl: string, body: Record<string, any>) {
    const url = new URL(this.#apiUrl + apiEndpointUrl)
    logger.log(`PUT ${url.href}`)
    const response = await this.#agent(url.href, {
      method: 'PUT',
      body: JSON.stringify({ ...body, cid: 'admin' }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (response.ok) {
      return response.text()
    }
    throw await response.json()
  }

  async delete(apiEndpointUrl: string) {
    const url = new URL(this.#apiUrl + apiEndpointUrl)
    url.searchParams.append('cid', 'admin')
    logger.log(`DELETE ${url.href}`)
    const response = await this.#agent(url.href, {
      method: 'DELETE',
    })
    if (response.ok) {
      return response.text()
    }
    throw await response.json()
  }
}
