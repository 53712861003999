import { postJSON, getJSON } from 'src/_utils/fetch-utils'
import { TOKEN_VALIDATED } from 'src/_constants/api-codes'

interface ValidateTokenResponse {
  /**
   * 1 = email is validated, 0 otherwise
   */
  check: 1 | 0
  
  code: number
  name: string
  uid: string
  mid: string
}

interface PermissionResponse {
  code: number
  msg: string
  roles: { uid: string, cid: string, roles?: string[], permissions: string[] }[]
}

export const ADMIN_PERMISSION = 'popadmin.login'

export async function validateToken({ token }: Record<string, string>) {
  const body = new FormData()
  body.append('token', token)
  const [result, permission]: [ValidateTokenResponse, PermissionResponse] = await Promise.all([
    postJSON('/token/check', body),
    getJSON('/permission/get', null, {
      'Authorization': `Popdaily ${token}`,
    }),
  ])
  const isEmailValidated = result.check === 1
  const hasAdminPermission = (permission?.roles ?? [])
    .reduce<string[]>((all, role) => all.concat(role.permissions), [])
    .includes(ADMIN_PERMISSION)
  if (result.code === TOKEN_VALIDATED && isEmailValidated && hasAdminPermission) {
    return result
  }
  if (isEmailValidated === false) {
    throw new Error('請驗證信箱後重新登入')
  }
  if (hasAdminPermission === false) {
    throw new Error('後台僅限擁有管理員權限人員使用')
  }
  throw new Error('認證失敗')
}
