import { Form, Input, DatePicker, Button, Switch, Radio } from 'antd'
import React from 'react'
import CategoryHeader from './CategoryEditorHeader'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { useMutation, useQuery } from '@apollo/client'
import { GetCategories } from '../../apollo-types/getCategories'
import { SetCategory, SetCategoryVariables } from '../../apollo-types/setCategory'
import { DELETE_CATEGORY, GET_CATEGORIES, SET_CATEGORY } from '../CategoryGQL'
import { routeConfig } from '../../_constants/routes'
import moment from 'moment'
import { DeleteCategory, DeleteCategoryVariables } from 'src/apollo-types/DeleteCategory'
import { useCallback } from 'react'

const { RangePicker } = DatePicker

export enum CategoryEditorMode {
  CREATE = 'create',
  EDIT = 'edit',
}
interface CategoryEditorProps {
  mode: CategoryEditorMode
}

const CategoryEditor = (props: CategoryEditorProps) => {
  const { mode } = props
  const { id } = useParams()
  const navigate = useNavigate()
  const { data, loading, error, refetch } = useQuery<GetCategories>(GET_CATEGORIES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      categoryType: 'ALL',
      categoryEnableType: 'ALL',
    },
  })

  const [setCategory, {}] = useMutation<SetCategory, SetCategoryVariables>(SET_CATEGORY, {
    onCompleted: () => {
      navigate(routeConfig.root.dashboard + routeConfig.dashboard.category)
    },
  })

  const [deleteCategory, {}] = useMutation<DeleteCategory, DeleteCategoryVariables>(
    DELETE_CATEGORY,
    {
      onCompleted: () => {
        navigate(routeConfig.root.dashboard + routeConfig.dashboard.category)
      },
    },
  )

  const categoryList = data?.categories ? data.categories : []
  const curCategory = categoryList.find((d) => d?.id === id)
  const type = curCategory?.type

  const onFinish = (values: any) => {
    const { slug, hashtag, validInterval, ...others } = values
    const hashTags = hashtag ? hashtag.split(',').map((s: string) => s.trim()) : []
    // format('YYYY-MM-DDT00:00:00Z') > 以日為單位編輯
    const startTime = validInterval?.[0]?.utc().format('YYYY-MM-DDT00:00:00Z') ?? null
    const endTime = validInterval?.[1]?.utc().format('YYYY-MM-DDT00:00:00Z') ?? null
    setCategory({
      variables: {
        input: {
          ...others,
          slug: slug.replace(/^(\s+)?\/(\s+)?/, ''),
          hashTags,
          startTime,
          endTime,
          type: 'event',
        },
      },
    })
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  const handleDelete = useCallback(() => {
    if (!curCategory?.id) return
    deleteCategory({
      variables: {
        slug: curCategory.id,
      },
    })
  }, [curCategory?.id])

  if (error) {
    navigate('/error')
    return null
  }

  return (
    <>
      <CategoryHeader mode={mode} />
      <Form
        name="category"
        labelCol={{ span: 2 }}
        wrapperCol={{ span: 15 }}
        layout="horizontal"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item wrapperCol={{ span: 17 }} className="text-right">
          <Button type="primary" htmlType="submit">
            {mode === CategoryEditorMode.CREATE ? '新增' : '儲存'}{' '}
          </Button>
          {mode === CategoryEditorMode.EDIT && (
            <Button type="default" htmlType="button" danger className="ml-2" onClick={handleDelete}>
              刪除
            </Button>
          )}
        </Form.Item>
        {type === 'main' ? (
          <>
            <Form.Item name="tags" label="標籤">
              <Input.TextArea placeholder="寫入標籤，請用英文逗號隔開, ex: 北海道, 四國, 大阪" />
            </Form.Item>
          </>
        ) : (
          <>
            <Form.Item
              label="分類"
              name="name"
              required
              rules={[
                { required: true, message: '請輸入分類名稱' },
                { max: 8, message: '分類標題最多8個字' },
              ]}
              initialValue={curCategory?.name}
            >
              <Input placeholder="請輸入分類名稱 ex: 白色聖經 🌟" />
            </Form.Item>
            <Form.Item
              label="分類路徑"
              name="slug"
              required
              rules={[{ required: true, message: '請輸入分類路徑' }]}
              initialValue={curCategory?.id}
            >
              <Input placeholder="請輸入分類路徑，格式為 “年份+名稱” ex: 2022whiteday" />
            </Form.Item>
            <Form.Item
              name="validInterval"
              label="有效區間"
              initialValue={[
                curCategory?.startTime ? moment(curCategory?.startTime) : null,
                curCategory?.endTime ? moment(curCategory?.endTime) : null,
              ]}
            >
              <RangePicker allowEmpty={[true, true]} showTime={false} />
            </Form.Item>
            <Form.Item
              name="hashtag"
              label="hashtag"
              initialValue={curCategory?.hashTags?.join() || ''}
            >
              <Input.TextArea
                // placeholder="寫入搜尋用的hashtag，請用英文逗號隔開，第一個請填分類名稱(不含 emoji), ex: 白色聖經, 白色"
                placeholder="寫入搜尋用的hashtag，請填分類名稱(不含 emoji), ex: 白色聖經"
              />
            </Form.Item>
            <Form.Item
              name="enabled"
              label="顯示"
              initialValue={curCategory ? !!curCategory?.enabled : true}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </>
        )}
      </Form>
    </>
  )
}

export default CategoryEditor
