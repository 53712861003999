import React from 'react'
import { Spin } from 'antd'

interface LoginSpinnerProps {
  children: JSX.Element
  isLoading: boolean
  tip: string
}

export default function LoginSpinner({ children, isLoading, tip }: LoginSpinnerProps) {
  const spinner = (
    <Spin size="large" tip={tip}>
      {children}
    </Spin>
  )
  return isLoading ? spinner : children
}
