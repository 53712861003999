import React from 'react'
import { Table, Space, Typography, Row, Col, Button, Tag } from 'antd'
import { useNavigate } from 'react-router'
import { navigatePath } from 'src/_constants/routes'
import { useRemoveDailyModule } from 'src/daily/daily.service'
import { DailyModule } from 'src/daily/daily.types'
import { DailyModuleState, DailyModuleStateLabel } from 'src/daily/daily.constants'
import moment from 'moment'
import type { ColumnsType } from 'antd/lib/table'

interface DailyModuleListProps {
  items: DailyModule[]
  onCreateDaily: () => void
  isLoading: boolean
}

export default function DailyModuleList({ items, onCreateDaily, isLoading }: DailyModuleListProps) {
  return (
    <>
      <Row>
        <Col span={12}>
          <Typography.Title level={2}>Daily 模組 - 列表</Typography.Title>
        </Col>
        <Col offset={8} span={4}>
          <Button type="primary" block onClick={onCreateDaily}>
            新增 Daily 頁面
          </Button>
        </Col>
      </Row>
      <Table rowKey="id" columns={columns} dataSource={items} bordered loading={isLoading} />
    </>
  )
}

function Actions({ id }: Pick<DailyModule, 'id'>) {
  const removeDailyModule = useRemoveDailyModule()
  const handleRemove = () => {
    if (!id) return
    const confirmed = window.confirm(`刪除 id=${id}？`)
    if (!confirmed) return
    removeDailyModule(id)
  }

  const navigate = useNavigate()
  const handleEdit = () => {
    if (!id) return
    navigate(navigatePath.editDaily.replace(':id', id))
  }

  return (
    <Space size="middle">
      <Button type="primary" onClick={handleEdit}>
        編輯
      </Button>
      <Button danger onClick={handleRemove}>
        刪除
      </Button>
    </Space>
  )
}

const statusInfo: Record<string, { label: string; color: string }> = {
  [DailyModuleStateLabel.DRAFT]: {
    label: '草稿',
    color: 'gold',
  },
  [DailyModuleStateLabel.SCHEDULED]: {
    label: '排程中',
    color: 'volcano',
  },
  [DailyModuleStateLabel.PUBLISHED]: {
    label: '已發布',
    color: 'green',
  },
  [DailyModuleStateLabel.DONE]: {
    label: '已結束',
    color: 'purple',
  },
}

const columns: ColumnsType<DailyModule> = [
  {
    title: '標題',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: '狀態',
    dataIndex: 'state',
    key: 'state',
    filters: [
      { value: DailyModuleStateLabel.DRAFT, text: '草稿' },
      { value: DailyModuleStateLabel.SCHEDULED, text: '排程中' },
      { value: DailyModuleStateLabel.PUBLISHED, text: '已發布' },
      { value: DailyModuleStateLabel.DONE, text: '已結束' },
    ],
    onFilter: (value, record) => { // value 是 render 出來的結果，record 是 data source item
      if (record.state === DailyModuleState.DRAFT) {
        return value === DailyModuleStateLabel.DRAFT
      }
      if (record.state === DailyModuleState.PUBLISHED && value === DailyModuleStateLabel.SCHEDULED) {
        return moment(record.startTime).isAfter(moment())
      }
      if (record.state === DailyModuleState.PUBLISHED && value === DailyModuleStateLabel.PUBLISHED) {
        return moment(record.startTime).isSameOrBefore(moment()) && moment(record.endTime).isAfter(moment())
      }
      if (record.state === DailyModuleState.PUBLISHED && value === DailyModuleStateLabel.DONE) {
        return moment(record.endTime).isSameOrBefore(moment())
      }
      return false
    },
    render: (_, record) => {
      let stateKey = ''
      if (record.state === DailyModuleState.DRAFT) {
        stateKey = DailyModuleStateLabel.DRAFT
      }
      if (record.state === DailyModuleState.PUBLISHED && moment(record.startTime).isAfter(moment())) {
        stateKey = DailyModuleStateLabel.SCHEDULED
      }
      if (record.state === DailyModuleState.PUBLISHED && moment(record.startTime).isSameOrBefore(moment()) && moment(record.endTime).isAfter(moment())) {
        stateKey = DailyModuleStateLabel.PUBLISHED
      }
      if (record.state === DailyModuleState.PUBLISHED && moment(record.endTime).isSameOrBefore(moment())) {
        stateKey = DailyModuleStateLabel.DONE
      }
      const { label = record.state, color } = statusInfo[stateKey] || {}
      return <Tag color={color}>{label}</Tag>
    },
  },
  {
    title: '排程開始',
    dataIndex: 'startTime',
    key: 'startTime',
    render: (text: string) => (text ? moment(text).format('YYYY-MM-DD HH:mm') : '未設定'),
    sorter: (a, b) => moment(a.startTime).valueOf() - moment(b.startTime).valueOf(),
  },
  {
    title: '排程結束',
    dataIndex: 'endTime',
    key: 'endTime',
    render: (text: string) => (text ? moment(text).format('YYYY-MM-DD HH:mm') : '未設定'),
    sorter: (a, b) => moment(a.endTime).valueOf() - moment(b.endTime).valueOf(),
  },
  {
    title: '建立時間',
    key: 'createdAt',
    dataIndex: 'createdAt',
    render: (text: string) => (text ? moment(text).format('YYYY-MM-DD HH:mm') : '未設定'),
    defaultSortOrder: 'descend',
    sorter: (a, b) => moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf(),
  },
  {
    title: '發布者',
    key: 'updatedBy',
    dataIndex: 'updatedBy',
  },
  {
    title: '操作',
    key: 'action',
    render: (text: string, record) => <Actions {...record} />,
  },
]
