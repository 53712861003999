import { gql } from '@apollo/client'

const GET_CATEGORIES = gql`
  query GetCategories($categoryType: CategoryType, $categoryEnableType: CategoryEnableType) {
    categories(categoryType: $categoryType, categoryEnableType: $categoryEnableType) {
      id
      name
      interest
      creator
      enabled
      editor
      postID
      description
      type
      hashTags
      startTime
      endTime
    }
  }
`

export default GET_CATEGORIES
