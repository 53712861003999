import React from 'react'
import { Routes, Route, Link } from 'react-router-dom'
import { routeConfig, navigatePath } from 'src/_constants/routes'

import Auth from './auth/auth.module'
import Login from './login/login.module'
import Dashboard from './dashboard/dashboard.module'
import { Daily, DailyList, DailyEditor, DailyModuleEditorMode } from './daily/daily.module'
import {
  ProductModelLayout,
  ProductModelList,
  ProductModelEditor,
  ProductModelEditorFormMode,
} from './productModel'
import { CategoryLayout, CategoryList, CategoryEditor, CategoryEditorMode } from './category'
import { FbVideo } from './fb_video/fb_video.module'
import ManuallyAddArticlesLayout from './manuallyAddArticles/ManuallyAddArticlesLayout'
import ManuallyAddArticlesPanel from './manuallyAddArticles/ManuallyAddArticlesPanel'

function App() {
  return (
    <Routes>
      <Route path={routeConfig.root.index} element={<Login redirectTo={navigatePath.daily} />} />
      <Route
        path={routeConfig.root.dashboard}
        element={
          <Auth redirectTo={navigatePath.daily}>
            <Dashboard />
          </Auth>
        }
      >
        <Route path={routeConfig.dashboard.fbVideo} element={<FbVideo />} />
        <Route path={routeConfig.dashboard.daily} element={<Daily />}>
          <Route path={routeConfig.daily.index} element={<DailyList />} />
          <Route
            path={routeConfig.daily.create}
            element={<DailyEditor mode={DailyModuleEditorMode.CREATE} />}
          />
          <Route
            path={routeConfig.daily.edit}
            element={<DailyEditor mode={DailyModuleEditorMode.EDIT} />}
          />
        </Route>
        <Route path={routeConfig.dashboard.productModel} element={<ProductModelLayout />}>
          <Route path={routeConfig.productModel.index} element={<ProductModelList />} />
          <Route
            path={routeConfig.productModel.create}
            element={<ProductModelEditor mode={ProductModelEditorFormMode.CREATE} />}
          />
          <Route
            path={routeConfig.productModel.edit}
            element={<ProductModelEditor mode={ProductModelEditorFormMode.EDIT} />}
          />
        </Route>
        <Route
          path={routeConfig.dashboard.manuallyAddArticles}
          element={<ManuallyAddArticlesLayout />}
        >
          <Route
            path={routeConfig.manuallyAddArticles.index}
            element={<ManuallyAddArticlesPanel />}
          />
        </Route>
        <Route path={routeConfig.dashboard.category} element={<CategoryLayout />}>
          <Route path={routeConfig.category.index} element={<CategoryList />} />
          <Route
            path={routeConfig.category.create}
            element={<CategoryEditor mode={CategoryEditorMode.CREATE} />}
          />
          <Route
            path={routeConfig.category.edit}
            element={<CategoryEditor mode={CategoryEditorMode.EDIT} />}
          />
        </Route>
        <Route path="*" element={<span>空的</span>} />
      </Route>
      <Route
        path={routeConfig.root.error}
        element={<Link to={navigatePath.index}>發生錯誤囉 QQ，點我返回首頁</Link>}
      ></Route>
      <Route path={routeConfig.root.login} element={<Login redirectTo={navigatePath.daily} />} />
      <Route path="*" element={<Link to={navigatePath.index}>迷路了？點這裡返回首頁</Link>} />
    </Routes>
  )
}

export default App
