import { gql } from '@apollo/client'

const DELETE_CATEGORY = gql`
  mutation DeleteCategory($slug: String!) {
    deleteCategory(slug: $slug) {
      id
      name
    }
  }
`

export default DELETE_CATEGORY
