import React from 'react'
import dayjs from 'dayjs'

const Interval = ({ interval = [] }: any) => {
  const [startDate, endDate] = interval.map((i: any) => (i ? dayjs(i).format('YYYY-MM-DD') : ''))

  if (!startDate && !endDate) {
    return <div>永久有效</div>
  }

  return (
    <div>
      {startDate} ~ {endDate}
    </div>
  )
}

export default Interval
