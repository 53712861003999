import hmacMd5 from 'crypto-js/hmac-md5'
import md5 from 'crypto-js/md5'
import { postJSON } from 'src/_utils/fetch-utils'
import { LOGIN_SUCCEDDED } from 'src/_constants/api-codes'

function hash(value: string) {
  return md5('pop' + hmacMd5(value, 'popdaily').toString()).toString()
}

export async function loginWithEmail({ username, password }: Record<string, string>) {
  const body = new FormData()
  body.append('email', username)
  body.append('pw', hash(password))
  const result = await postJSON('/login/email', body)
  if (result.code === LOGIN_SUCCEDDED) {
    return result
  }
  throw new Error(result.msg)
}
