import React from 'react'
// prettier-ignore
import { Button, Col, Form, Input, Row, Space, Typography } from 'antd'
import { useFbVideo, useFbVideoSubmit } from 'src/fb_video/fb_video.service'

export default function FbVideoEditor() {
  const [fbVideo, status] = useFbVideo()
  const initialValues = fbVideo ? { htmlCode: fbVideo.htmlCode } : undefined
  const [isLoading, submit] = useFbVideoSubmit()
  const [form] = Form.useForm()

  const reset = () => {
    form.resetFields()
  }

  const formFields = {
    htmlCode: {
      name: 'htmlCode',
      label: 'HTML 內容',
      rules: [{ required: true, message: '請輸入 HTML' }],
    },
  }

  if (status !== 'success') return '載入中...'

  return (
    <Form
      name="fb-video"
      form={form}
      initialValues={initialValues}
      layout={'vertical'}
      hideRequiredMark
      onFinish={submit}
    >
      <Row>
        <Col span={12}>
          <Typography.Title level={2}>Facebook 全站影片</Typography.Title>
        </Col>
        <Col span={12} className="text-right">
          <Space>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              儲存
            </Button>
            <Button danger onClick={reset}>
              還原
            </Button>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item {...formFields.htmlCode}>
            <Input.TextArea placeholder="在此輸入 HTML 內容" rows={20} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}
